<template>
  <PdpTabBase
    :headline="infoText.type"
    :data-preload="[$options.name]"
    :is-expanded="activeTab"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div class="pdp-info-texts-tab__content" v-html="infoText.value" />
  </PdpTabBase>
</template>

<script>
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';
import { InfoTextsEnum } from 'Src/components/03-organisms/product-details-21/models/info-texts.enum';

const TabNameEnum = {
  PdpGeneralInfoTab: 'GENERAL_INFO',
  PdpTechInfoTab: 'TECH_INFO',
};

export default {
  name: 'PdpInfoTextsTab',
  components: { PdpTabBase },
  props: {
    tabName: {
      type: String,
      default: '',
      required: true,
    },
    infoTexts: {
      type: Array,
      default: () => [],
      required: true,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    infoText() {
      const tabName = TabNameEnum[this.tabName];
      if (!tabName) return { type: '' };

      return this.infoTexts.find(
        (info) => info.type === InfoTextsEnum[tabName]
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.pdp-info-texts-tab {
  &__content {
    white-space: pre-line;
    font-size: var(--font-size-M);
    line-height: var(--font-size-MLL);

    a {
      color: var(--color-secondary);
      text-decoration: underline;
    }

    p + p {
      margin-top: 1em;
    }
  }
}
</style>
